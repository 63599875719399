<div class="sidebar-inner">
	<div class="sidebar-header">
		<div long-press (onLongPress)="showVersion()" class="site-logo">
			<img src="assets/logo-square_2023.svg" alt="mrd-rssp">
		</div>
	</div>
	
	<div class="sidebar-body">
		<div class="sidebar-menu">
			<div *ngFor="let opt of options$ | async" class="link-item">
				<a *ngIf="opt.routerLink" [class]="opt.mdiClass" [routerLink]="opt.routerLink" routerLinkActive="active" [id]="'lnk'+opt.slug" [antic]="opt.faEvent">
					<span>
						{{opt.mdi}} 
						{{isGroupRole && opt.slug.includes('indiedashboard') ? 'Restaurants' : opt.name}}
						<span *ngIf="opt.att" class="nav-item-badge">{{opt.att}}</span>
					</span>
					<span *ngIf="opt.badgeCount" class="countBadge" userColor="backgroundColor">{{opt.badgeCount? opt.badgeCount : ''}}</span>
				</a>
				<button *ngIf="opt.event" class="btn btn-link" [class]="opt.mdiClass" (click)="onOptionClick(opt)" [id]="'btn'+opt.slug">
					<span>{{opt.mdi}} {{opt.name}}</span>
				</button>
			</div>
		</div>
	</div>
	
	<!-- <div class="sidebar-banner" *ngIf="displayMenuBanner">
		<h3 userColor>Boost your sales</h3>
		<img src="assets/promos/boost_card_illustration_rocket.png">
		<a *appMayView="['promotion_create']" class="btn secondary" (click)="logEvent_CreatePromo()"
			[routerLink]="['/promos','new']" id="lnkNew" userColor="backgroundColor">
			Create promotion
		</a>
	</div> -->
	
	<div class="sidebar-footer">
		<div class="user-profile flex" *ngIf="(profileState$ | async) as p">
			<div class="avatar" userColor="backgroundColor" id="lblAvatar">
				{{p.profile? p.profile.first_name.substring(0,1) : ''}}
			</div>
			<div class="profile-user">
				<div class="name">{{p.profile?.first_name}} {{p.profile?.last_name}}</div>
				<div class="role">{{p.profile?.role_name | role}}</div>
			</div>
			<div class="action textRight">
				<button class="button button-action" (click)="ShowAction('profile-action')" id="btnProfile"><span class="mdi mdi-dots-horizontal"></span></button>
			</div>
		</div>
	
	</div>
	
	<div *contextMenu="'profile-menu'" [@fadeInOut] id="profile_details" class="action-menu user-action profile-action">
			<div class="action-body" *ngIf="(profileState$ | async) as p">
			<div class="profile-name" id="lblSideNavFullName">{{p.profile?.first_name}} {{p.profile?.last_name}}</div>
			<div class="profile-role" id="lblSideNavRoleName">{{p.profile?.role_name | role}}</div>
			<div class="profile-number">{{p.profile?.phone_number}}</div>
			<div class="profile-email" id="lblSideNavEmail">{{p.profile?.email }}</div>
			<!-- <button class="link user-details" routerLink="/me">Update Details</button> -->
			<div class="terms-links flex">
				<button (click)="showTC()" style="margin-bottom:10px;" id="btnTerms">Terms of service</button>
			</div>
		</div>
		<div class="action-footer">
			<button class="link" (click)="logout()" id="btnLogout">
				<span id="logout-warning" userColor="backgroundColor"></span>
				<span class="mdi mdi-logout-variant"></span>
				Sign Out
			</button>
		</div>
	</div>
	
</div>