import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UserRole } from 'src/app/entities/user-role';
import { IdName } from 'src/app/entities/global';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/api/user.service';
import { Location } from '@angular/common';

export const GROUP_URL_PATHS: Array<string> = ['/group-dashboard', '/reports'];

export const isGroupPage = (url: string, role: UserRole): boolean => {
	const algo = url.substring(0,url.indexOf('?'));
	return GROUP_URL_PATHS.includes(algo.length > 0 ? algo : url) && role === UserRole.GroupHead;
};

export interface IReportList extends IdName {
	tag?: string;
}

/**
 * https://mrdelivery.atlassian.net/wiki/spaces/FD/pages/2626617349/Restaurant+Self+Service+Portal+RSSP+-+Group+Reporting
 * Rules for 'negative' UI flow:
 * - The bulk of the properties below would be a negative status if property > incoming data
 * - The only exception to the rule here is if property 'avg_food_rating' < incoming data (ie the reverse)
 */
export enum OpCardBenchmarks {
	avg_e2e_delivery_time = 32,
	avg_selected_prep_time = 19,
	avg_time_order_accepted_collected = 19.32,
	orders_delivered = 0.5,
	missed_rejected = 1,
	downtime = 5,
	handover_delay = 3,
	avg_food_rating = 4.75,
}

export enum Units {
	mins = 'mins',
	perc = '%',
	orders = 'orders',
	downtime = 'd',
	none = '',
}

export enum ErrorStates {
	customer = 'Slower than customer expectation',
	platform = 'Below platform standard',
	platform_higher = 'Higher than platform standard',
	revenue = 'Missed Revenue: ',
	goal = 'Goal: Less than 5%',
}

export enum SuccessStates {
	customer = 'Faster than/in-line with customer expectation',
	platform = 'In line with platform standard',
	platform_higher = 'Lower than platform standard',
	revenue = 'Missed Revenue:',
	goal = 'Goal: Less than 5%',
}

export enum GroupOperationTypes {
	group_order_statuses_report = 'group_order_statuses_report',
	group_avg_order_value_report = 'group_avg_order_value_report',
	group_num_orders_report = 'group_num_orders_report',
	group_total_revenue_report = 'group_total_revenue_report',
	group_order_time_stats_report = 'group_order_time_stats_report',
	group_avg_food_rating_report = 'group_avg_food_rating_report',
	group_avg_delivery_time_report = 'group_avg_delivery_time_report',
	group_avg_prep_time_report = 'group_avg_prep_time_report',
	group_missing_item_orders_report = 'group_missing_item_orders_report',
	group_incorrect_item_orders_report = 'group_incorrect_item_orders_report',
	group_handover_delay_report = 'group_handover_delay_report',
	group_avg_collection_time_report = 'group_avg_collection_time_report',
	group_full_report = 'group_full_report',
	group_downtime_report = 'group_downtime_report',
}

export enum GroupTypeTextMapper {
	group_order_statuses_report = 'Missed/Rejected Orders Report',
	group_avg_order_value_report = 'Avg Food Value Report',
	group_num_orders_report = 'Orders Report',
	group_total_revenue_report = 'Food Value Sold Report',
	group_avg_collection_time_report = 'Avg Time From Order Accepted To Collected Report',
	group_avg_food_rating_report = 'Avg Food Quality Rating Report',
	group_avg_delivery_time_report = 'Avg End-To-End Delivery Time Report',
	group_avg_prep_time_report = 'Avg Selected Prep Time Report',
	group_missing_item_orders_report = 'Orders Delivered With Missing Items Report',
	group_incorrect_item_orders_report = 'Orders Delivered With Incorrect Items Report',
	group_handover_delay_report = 'Handover Delay Report',
	group_full_report = 'Full Report',
	group_downtime_report = 'Downtime Report'

}

export const groupReportTypes: IReportList[] = [ // **PS/NB: do NOT change the ordering of these elements!!
	{ id: GroupOperationTypes.group_full_report, name: 'Full report', tag: 'full' },
	{ id: GroupOperationTypes.group_total_revenue_report, name: 'Food value sold', tag: 'food_value_sold' }, // 1
	{ id: GroupOperationTypes.group_num_orders_report, name: 'Orders', tag: 'orders' },
	{ id: GroupOperationTypes.group_avg_order_value_report, name: 'Avg food value', tag: 'average_food_value' },
	{ id: GroupOperationTypes.group_order_statuses_report, name: 'Missed/rejected orders', tag: 'missed_rejected_orders' }, // 4
	{ id: GroupOperationTypes.group_avg_food_rating_report, name: 'Avg food quality rating', tag: 'avg_food_quality_rating' },
	{ id: GroupOperationTypes.group_avg_prep_time_report, name: 'Avg selected prep time', tag: 'avg_preparation_time' },
	{ id: GroupOperationTypes.group_handover_delay_report, name: 'Handover delay', tag: 'handover_delay' },
	{ id: GroupOperationTypes.group_avg_delivery_time_report, name: 'Avg end-to-end delivery time', tag: 'avg_delivery_time' },
	{ id: GroupOperationTypes.group_avg_collection_time_report, name: 'Avg time from order accepted to collected', tag: 'avg_time_from_order_accepted_to_food_collected' }, // 9
	{ id: GroupOperationTypes.group_incorrect_item_orders_report, name: 'Orders delivered with incorrect items', tag: 'orders_delivered_with_incorrect_items' },
	{ id: GroupOperationTypes.group_missing_item_orders_report, name: 'Orders delivered with missing items', tag: 'orders_delivered_with_missing_items' },
	{ id: GroupOperationTypes.group_downtime_report, name: 'Downtime', tag: 'downtime' },
];
export interface IRestaurantBreakDown {
	current: {
		[seriersKey: string]: any | null
	};
	previous: {
		[seriersKey: string]: any | null
	};	
	id: number;
	name: string;
	percent_change: any;
}
export interface IStats {
  current: {
		[seriesKey: string]: any;
	};
  previous: {
		[seriesKey: string]: any;
	};
	per_restaurant_breakdown: IRestaurantBreakDown[];
	breakdown: string; //IRestaurantBreakDown[];
	metric: number;
	status: {
		needsAttention: boolean;
		text: ErrorStates | SuccessStates
	};
	slug: GroupOperationTypes;
	unit: Units;
	hasOrders?: boolean;
	isMissedRejected?: boolean;
	isFoodRating?: boolean;
	rejectedRevenue?: number;
	orders?: number;
	test1?: number;
	test2?: number;
	calcTest?: number;
	downtime?: string;
	goal: number;
}
export interface IOnlineRatio {
  current: {
    //actual_online_time: number,
		//benchmark_online_ratio: number,
    available_online_time: number,
		downtime: number,
    downtime_ratio: number,
  },
  previous?: {
    //actual_online_time: number,
    available_online_time: number,
		downtime: number,
    downtime_ratio: number,
  }
}
export interface IOrderTimes {
  current: {
    avg_prep_time: number;
    recommended_prep_time: number;
    handover_delay_ratio: number;
    benchmark_handover_delay_ratio: number;
    num_delayed_orders: number;
  };
  previous?: {
    avg_prep_time: number;
    recommended_prep_time: number;
    handover_delay_ratio: number;
    benchmark_handover_delay_ratio: number;
  };
}
export interface IPopularItemList {
  popular_item_list: {
    description: string;
    quantity_sold: number;
    orders: number;
  }[];
}
export interface IRatings {
  total: number;
  current_average: number;
  previous_average?: number;
  ratings: {
    rating: number;
    count: number;
  }[];
}
export interface INumberOfOrders {
  current: {
    points: {
      x_tick: string,
      y_ticks: {
        series_1: number
      }
    }[],
		commission?:number,
		owed_to_restaurant?: number,
    meta: {
      series_1: {
        label: string,
        Description: string
      }
    },
    rounded_max_y: number,
    total: number,
    avg_order_value: number,
  },
  previous: {
    points: {
      x_tick: string,
      y_ticks: {
        series_1: number
      }
    }[],
		commission?:number,
		owed_to_restaurant?: number,		
    meta: {
      series_1: {
        label: string,
        Description: string
      }
    },
    rounded_max_y: number,
    total: number,
    avg_order_value: number,
  },
	per_restaurant_breakdown?: IRestaurantBreakDown[],
  percentage_change: number;
  timeInterval: string;
  rounded_max_y: number;
}
export interface IReportState {
	queryParams : {
		startDate: Date,
		endDate: Date,
		reportType: string, 
		autoOpenModal: boolean,
	}
}

export function getStartAndEnd(start: Date, end: Date) {
  return {
    epoch_start: Math.round(start.getTime() * 0.001),
    epoch_end: Math.round(end.getTime() * 0.001),
  };
}

@Injectable({
  providedIn: 'root',
  })
export class GroupDashboardService {
  constructor(
    private http: HttpClient,
		private router: Router,
		private user: UserService,
		private location: Location,
  ) { }

	private _dateSelected$: BehaviorSubject<{start: Date, end: Date}> = new BehaviorSubject({start: new Date(), end: new Date()});
	readonly dateSelected$ = this._dateSelected$.asObservable();

	isGroupHeadUser$ = (): Observable<boolean> => {
		return this.user.profile.pipe(
			map((p) => p.profile?.role_name),
			switchMap((r) => {
				return of(r === UserRole.GroupHead)
			})
		);
	}

	updateSelectedDate({start, end}) {
		this._dateSelected$.next({start, end});
	}

	downloadReport = (s: IReportState) => {
		this.router.navigate(['/reports'], { queryParams: {
			startDate: s.queryParams.startDate,
			endDate: s.queryParams.endDate,
			reportType: s.queryParams.reportType,
			autoOpenModal: s.queryParams.autoOpenModal,			
		}});
	}	

  getMiscStats(
    id: string,
    start: Date,
    end: Date,
		stats_type: string,
  ) {
    const { epoch_start, epoch_end } = getStartAndEnd(start, end);
		const params = [
			['epoch_start', epoch_start],
			['epoch_end', epoch_end],
			['group_id', id],
			['include_previous', true],
			['include_per_restaurant_breakdown', true],
			['stat', stats_type],
		];
		const query = params.map((p) => `${p[0]}=${p[1]}`).join('&');
    return this.http.get<IStats>(`api://analytics/misc-stats?${query}`);
  }

  getOrderStats(
    groupId: string,
    start: Date,
    end: Date,
  ) {
    const { epoch_start, epoch_end } = getStartAndEnd(start, end);
		const params = [
			['epoch_start', epoch_start],
			['epoch_end', epoch_end],
			['include_previous', true],
			['include_per_restaurant_breakdown', true],
		];
		const query = params.map((p) => `${p[0]}=${p[1]}`).join('&');		
    return this.http.get<IStats>(`api://analytics/groups/${groupId}/order-statuses?${query}`);
  }
  getOnlineRatio(
    groupId: string,
    start: Date,
    end: Date,
  ) {
    const { epoch_start, epoch_end } = getStartAndEnd(start, end);
		const params = [
			['epoch_start', epoch_start],
			['epoch_end', epoch_end],
			['include_previous', true],
			['include_per_restaurant_breakdown', true],
		];
		const query = params.map((p) => `${p[0]}=${p[1]}`).join('&');			
    return this.http.get<IOnlineRatio>(`api://analytics/groups/${groupId}/online-ratio?${query}`);
  }
  getOrderTimeStats(
    groupId: string,
    start: Date,
    end: Date,
  ) {
    const { epoch_start, epoch_end } = getStartAndEnd(start, end);
		const params = [
			['epoch_start', epoch_start],
			['epoch_end', epoch_end],
			['include_per_restaurant_breakdown', true],
		];
		const query = params.map((p) => `${p[0]}=${p[1]}`).join('&');			
    return this.http.get<IStats>(`api://analytics/groups/${groupId}/order-time-stats?${query}`);
  }
  getRevenueTotals(
    groupId: string,
    timeInterval: string,
    start: Date,
    end: Date,
  ) {
    const { epoch_start, epoch_end } = getStartAndEnd(start, end);
		const params = [
			['epoch_start', epoch_start],
			['epoch_end', epoch_end],
			['include_previous', true],
			['include_per_restaurant_breakdown', true],
			['time_interval', timeInterval],
		];
		const query = params.map((p) => `${p[0]}=${p[1]}`).join('&');		
    return this.http.get<INumberOfOrders>(`api://analytics/groups/${groupId}/revenue/total?${query}`)
      .pipe(
        map((x) => {
          // choose highest current or previous for y axis
          x.rounded_max_y = Math.max(x.current.rounded_max_y, x.previous.rounded_max_y);

          // percentage change from last period
          const cur = x.current.total;
          const prev = x.previous.total;
          const bothNoneZero = cur > 0 && prev > 0;

          if (cur > prev && bothNoneZero) {
            x.percentage_change = Math.round(((cur - prev) / prev) * 100);
          } else if (cur < prev && bothNoneZero) {
            x.percentage_change = -Math.round(((prev - cur) / prev) * 100);
          } else {
            x.percentage_change = 0;
          }

          // match previous and current lengths
          if (x.current.points.length > x.previous.points.length) { // current longer
            for (let i = x.previous.points.length; i < x.current.points.length; i++) {
              x.previous.points.push({
                x_tick: '',
                y_ticks: { series_1: 0 },
              });
            }
          } else if (x.current.points.length < x.previous.points.length) { // previous longer
          }

          return x;
        }),
      );
  }

  getPopularItems(
    groupId: string,
    start: Date,
    end: Date,
  ) {
    const { epoch_start, epoch_end } = getStartAndEnd(start, end);
    return this.http.get<IPopularItemList>(`api://analytics/groups/${groupId}/popular-items?epoch_start=${epoch_start}&epoch_end=${epoch_end}`);
  }

  getAOV(
    groupId: string,
    timeInterval: string,
    start: Date,
    end: Date,
  ) {
    const { epoch_start, epoch_end } = getStartAndEnd(start, end);
		const params = [
			['epoch_start', epoch_start],
			['epoch_end', epoch_end],
			['include_previous', true],
			['include_per_restaurant_breakdown', true],
			['time_interval', timeInterval],
		];
		const query = params.map((p) => `${p[0]}=${p[1]}`).join('&');
    return this.http.get<INumberOfOrders>(`api://analytics/groups/${groupId}/revenue/avg-order-value?${query}`)
      .pipe(
        map((x) => {
          // choose highest current or previous for y axis
          x.rounded_max_y = Math.max(x.current.rounded_max_y, x.previous.rounded_max_y);

          // percentage change from last period
          const cur = x.current.avg_order_value;
          const prev = x.previous.avg_order_value;
          const bothNoneZero = cur > 0 && prev > 0;

          if (cur > prev && bothNoneZero) {
            x.percentage_change = Math.round(((cur - prev) / prev) * 100);
          } else if (cur < prev && bothNoneZero) {
            x.percentage_change = -Math.round(((prev - cur) / prev) * 100);
          } else {
            x.percentage_change = 0;
          }

          if (x.current.points.length > x.previous.points.length) { // current longer
            for (let i = x.previous.points.length; i < x.current.points.length; i++) {
              x.previous.points.push({
                x_tick: '',
                y_ticks: { series_1: 0 },
              });
            }
          }

          return x;
        }),
      );
  }

  getNumberOfOrders(
    groupId: string,
    timeInterval: string,
    start: Date,
    end: Date,
  ) {
    const { epoch_start, epoch_end } = getStartAndEnd(start, end);
		const params = [
			['epoch_start', epoch_start],
			['epoch_end', epoch_end],
			['include_previous', true],
			['include_per_restaurant_breakdown', true],
			['time_interval', timeInterval],
		];
		const query = params.map((p) => `${p[0]}=${p[1]}`).join('&');
    return this.http.get<INumberOfOrders>(`api://analytics/groups/${groupId}/revenue/num-orders?${query}`)
      .pipe(
        map((x) => {
          // choose highest current or previous for y axis
          x.rounded_max_y = Math.max(x.current.rounded_max_y, x.previous.rounded_max_y);

          // percentage change from last period
          const cur = x.current.total;
          const prev = x.previous.total;
          const bothNoneZero = cur > 0 && prev > 0;

          if (cur > prev && bothNoneZero) {
            x.percentage_change = Math.round(((cur - prev) / prev) * 100);
          } else if (cur < prev && bothNoneZero) {
            x.percentage_change = -Math.round(((prev - cur) / prev) * 100);
          } else {
            x.percentage_change = 0;
          }

          if (x.current.points.length > x.previous.points.length) { // current longer
            for (let i = x.previous.points.length; i < x.current.points.length; i++) {
              x.previous.points.push({
                x_tick: '',
                y_ticks: { series_1: 0 },
              });
            }
          }

          return x;
        }),
      );
  }

}
