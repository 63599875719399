/* eslint-disable no-return-assign */
import {
  Component, OnInit, OnDestroy, Input,
} from '@angular/core';
import { combineLatest, EMPTY, Observable, of, Subscription } from 'rxjs';

import { IGroup, IRestaurant } from 'src/app/entities/restaurant';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import {
  startWith, map, debounceTime, 
	distinctUntilChanged, tap, delay, filter,
	switchMap,
} from 'rxjs/operators';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { LSKEY_SELECTED_GROUP, RestaurantService } from 'src/app/services/api/restaurant.service';
import { IPageResult } from 'src/app/entities/global';
import { FirebaseAnalyticsService } from 'src/app/services/firebase_analytics.service';
import { IProfile, UserService } from 'src/app/services/api/user.service';
import { userRoles } from 'src/app/entities/user-role';
import { RestaurantSelectorService } from './restaurant-selector.service';
import { isGroupPage } from 'src/app/modules/group-dashboard/services/group-dashboard.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: '[restaurantselect]',
  templateUrl: './restaurant-selector.component.html',
  styleUrls: ['./restaurant-selector.component.scss'],
  })
export class RestaurantSelectorComponent implements OnInit, OnDestroy {
  @Input() hostPageName: string;
  @Input() hideCreatePromoBtn: boolean;
	
	isGroupPage: boolean;

	modalTitle: string = 'Choose restaurant';

  loading = false;

  filter = new UntypedFormGroup({
    name: new UntypedFormControl(''),
  });

  page$: Observable<IPageResult<IRestaurant | IGroup>>;
	cachedPage$: Observable<IPageResult<IRestaurant | IGroup>>;
  selected: IRestaurant | IGroup;
  hasMoreRestaurants = false;
  externalReq: Subscription;
	selectedName_restaurant: string = null;
	selectedName_group: string = null;

  modalIsOpen = false;
  nextFn: Function | undefined; // fn to invoke after restaurant chosen

  private subSelected: Subscription;

  userRole: IProfile;

  constructor(
    // private me: UserService,
    private restaurant: RestaurantService,
    private route: ActivatedRoute,
    private ngFA: FirebaseAnalyticsService,
    private restaurantSelector: RestaurantSelectorService,
    private user: UserService,
    private location: Location,
		private store: StoreService,
  ) {

    const profile$ = this.user.profile.pipe(
      filter((data) => !!data.profile),
      map((data) => data.profile),
    );

    // 1. what does the URL want
    const query$ = this.route.queryParamMap.pipe(
      map((q) => {
        const params: {user_id?: string, restaurant_set_id?: string} = {};
        if (q.has('as')) { params.user_id = q.get('as'); }
        if (q.has('for')) { params.restaurant_set_id = q.get('for'); }
        return params;
      }),
    );
    // 2. what does the UI want
    const name$ = this.filter.controls.name.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      //startWith(this.filter.controls.name.value),
			startWith(''),
    );
    // 3. Visibility of this component also depends on the route
    // e.g. if it was shown for the dashboard and the user decided to dismiss it,
    // it has to be shown again if the user visits a different route that requires a
    // restaurant to be selected
    const currentUrl$ = new Observable<string>((o) => {
      o.next(this.location.path()); // emit current url first...
      const unsub = this.location.onUrlChange((u) => {
        o.next(u); // ... and then any subsequent url changes
      });
      return unsub;
    });

    // 3. combine 1 and 2 and filter the results
    this.page$ = combineLatest([profile$, name$, query$, currentUrl$]).pipe(
      map(([profile, name, q, url]) => ({ profile, name, q, url })),
      tap((data: {profile: IProfile, url: string}) => {
				this.userRole = data.profile;
				this.isGroupPage = isGroupPage(data.url, data.profile.role_name);
				// this.selected = null;
			}),
			// tap(() => alert('this.modalIsOpen: '+ this.modalIsOpen)),
			//switchMap((data)=> this.modalIsOpen ? of(data) : EMPTY),
      this.restaurant.filterToList(), // operator adds server/client-side filtering
      delay(0), // avoiding ExpressionChangedAfterItHasBeenCheckedError
      tap((page) => {
				
				// dynamic title
				this.modalTitle = `Choose ${this.isGroupPage ? 'group' : 'restaurant'}`;
        const getRole = userRoles.find((r) => r.id === this.userRole.role_name);
				this.hasMoreRestaurants = page.list.length > 1;
				if (this.isGroupPage) {

					// Update Group Local Storage data live from network call - to update UI immediately
					const LS_GROUP = this.store.get(LSKEY_SELECTED_GROUP);
					if (LS_GROUP) {
						const LSData = (JSON.parse(LS_GROUP) as IGroup);
						const updatedDataForLS = page.list.find((i) => +(i.group_id) === +(LSData.id));
						if (updatedDataForLS && updatedDataForLS.restaurants.length != LSData.restaurants.length) {
							this.restaurant.selectGroup((updatedDataForLS as IGroup));
						}
					}

					this.restaurant.selectedGroup$.pipe(delay(0)).subscribe(selectedGroup => {
						if (selectedGroup) {
							this.selected = selectedGroup;
							this.selectedName_group = selectedGroup.name;
						} else {
							this.showList();
						}						
					});
				} else {
					this.restaurant.selectedRestaurant$.pipe(delay(0)).subscribe(selectedRestaurant => {
						if (selectedRestaurant) {
							this.selected = selectedRestaurant;
							this.selectedName_restaurant = selectedRestaurant.name;
						} else {
							this.showList();
						}
					});
				}
				/*
        if (!this.selected && page.list.length === 1) {
          this.showList();
        }
        if ((!this.selected && this.hasMoreRestaurants)
          || (!this.selected
            && !!getRole.showRestPopupList && getRole.showRestPopupList)) {
          this.showList();
        } // open modal if no restaurant*/
      }),
    );
  }

  ngOnInit(): void {	
    // Might be a request from within a page to show selector, e.g.
    // prompting user to select restaurant before performing restaurant-
    // specific action like requesting a report
    this.externalReq = this.restaurantSelector.showSelector$
      .subscribe((nextFn?) => this.showList(nextFn));
  }

  select(item: IGroup) {
		// get selection from the list and send service
		if (this.isGroupPage) {
			this.restaurant.selectGroup(item);
			this.selectedName_group = item.name;
		} else {
			this.restaurant.selectRestaurant(item);
			this.selectedName_restaurant = item.name;
		}
    if (this.nextFn) this.nextFn();
    this.modalIsOpen = false;
		this.filter.reset();
    this.ngFA.logEvent('tap_switch_restaurant', { restaurant_name: `${item.name} ${item.suburb}` });
  }

  showList(nextFn?) {
    this.modalIsOpen = true;
    this.nextFn = nextFn;
  }

  ngOnDestroy() {
    this.externalReq.unsubscribe();
  }

  logEvent_CreatePromo() {
    this.ngFA.logEvent('tap_create_promos', { source_page: this.hostPageName });
  }
	
	onModalClose() {
		this.modalIsOpen = false;
		this.filter.reset();
	}	
}
